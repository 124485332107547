<template>
  <div class="container center" style="margin: auto">
    <h1>String tension calculator</h1>

    <hr />

    <div>
      <!-- TODO: add actions that affect all rows -->
      <table
        class="table table-striped table-bordered table-sm"
        style="margin: auto"
      >
        <thead>
          <tr>
            <th>Strings</th>
            <th colspan="4">Models</th>
            <th>SL treble side</th>
            <th>SL Bass side</th>
          </tr>

          <tr>
            <th>
              <select v-model="strings" class="form-control form-select">
                <option
                  :key="i"
                  v-for="i in [4, 5, 6, 7, 8, 9, 10, 11, 12]"
                  :value="i"
                >
                  {{ i }}
                </option>
              </select>
            </th>
            <th colspan="4">
              <select v-model="selectedModel" class="form-select form-control">
                <option value="" disabled selected>Kies een Preset</option>
                <option value=""></option>
                <option
                  :key="index"
                  v-for="(model, index) in models"
                  :value="model.id"
                >
                  {{ model.name }}
                </option>
              </select>
            </th>
            <th>
              <input
                type="number"
                class="form-control"
                v-model.number="multiRange.min"
                step=".5"
                @blur="setMultiRange"
                @change="setMultiRange"
              />
            </th>
            <th>
              <input
                type="number"
                class="form-control"
                v-model.number="multiRange.max"
                step=".5"
                @blur="setMultiRange"
                @change="setMultiRange"
              />
            </th>
          </tr>
          <tr>
            <th>String #</th>
            <th>Note</th>
            <th>Octave</th>
            <th>
              String type
              <span style="margin-left: 15px; color: grey">
                <i>
                  (filtered:
                  <input type="checkbox" v-model="filteredStrings" />)
                </i>
              </span>
            </th>
            <th>Gauge</th>
            <th>Scale length (inch)</th>
            <th>Tension (lb)</th>
          </tr>
        </thead>
        <tbody>
          <TensionCalc
            :key="i"
            v-for="i in stringsInSet"
            :i="i"
            :rounded="rounded"
            :string="stringSet[i]"
            :filteredStrings="filteredStrings"
          />
        </tbody>
      </table>

      <!-- TODO: make popup or something to make the export more clear and less visually present -->

      <hr />
      <form @submit.prevent="exportStateAsJSON">
        <input
          type="text"
          v-model="name"
          placeholder="preset naam"
          required
          minlength="3"
        />
        <button type="submit" class="btn btn-primary btn-sm">
          Download set (JSON)
        </button>
      </form>
    </div>
  </div>
</template>

<script>
// TODO: reset preset after change
// TODO: round string length (a lot of digits)
// TODO: set strings after preset change (also length when multiLength)
// TODO: display number of strings in preset select

import Vue from "vue";
import TensionCalc from "./components/TensionCalc.vue";
import models from "./data/models.json";

export default Vue.extend({
  name: "App",
  data() {
    return {
      name: "",
      strings: 6,
      multiRange: {
        min: 25.5,
        max: 25.5,
      },
      rounded: true,
      models: models,
      selectedModel: null,
      stringSet: {},
      filteredStrings: true,
    };
  },
  components: {
    TensionCalc,
  },

  watch: {
    selectedModel(newValue) {
      this.stringSet = this.models.find((i) => i.id == newValue).preset;
      let strings = this.models.find((i) => i.id == newValue).strings;
      this.strings = strings;
      this.multiRange.min = this.stringSet[1].length;
      this.multiRange.max = this.stringSet[strings].length;

      // this.multiRange.max = ;
    },

    strings(newValue) {
      this.setStrings();
    },
  },

  computed: {
    stringsInSet: {
      get() {
        return Object.keys(this.stringSet);
      },
      set(newValue) {
        console.log(newValue);
      },
    },
  },

  methods: {
    setMultiRange() {
      if (!(this.multiRange.min && this.multiRange.max)) {
        return "";
      }
      let min = this.multiRange.min;
      let max = this.multiRange.max;
      let dif = max - min;
      console.log("dif", dif);
      let step = dif / (this.stringsInSet.length - 1);
      console.log("step", step);

      this.stringsInSet.forEach((i) => {
        this.stringSet[i].length = this.multiRange.min + step * (i - 1);
      });
    },

    exportStateAsJSON() {
      let jsonData = {
        id: new Date().getTime(),
        name: this.name,
        strings: this.strings,
        preset: this.stringSet,
      };

      let dataStr = JSON.stringify(jsonData);
      let dataUri =
        "data:application/json;charset=utf-8," + encodeURIComponent(dataStr);

      let exportFileDefaultName = this.name + "_data.json";

      let linkElement = document.createElement("a");
      linkElement.setAttribute("href", dataUri);
      linkElement.setAttribute("download", exportFileDefaultName);
      linkElement.click();
    },

    setStrings() {
      let def = {
        note: null,
        octave: null,
        string_producer: null,
        string_type: null,
        string_id: null,
        mass: null,
        length: 25.5,
      };

      let currentStrings = Object.keys(this.stringSet).length;
      console.log("current stinrgs", currentStrings);
      if (currentStrings < this.strings) {
        let amount = this.strings - currentStrings;
        console.log(`adding ${amount} extra strings`);
        for (let i = 1; i < amount + 1; i++) {
          // this.stringSet[String(currentStrings + i)] = def;
          // this.$set(this.currentStrings, String(currentStrings + i), def);
          this.stringSet = {
            ...this.stringSet,
            [currentStrings + i]: { ...def },
          };
        }
      } else if (currentStrings > this.strings) {
        let amount = currentStrings - this.strings;
        let place = { ...this.stringSet };
        console.log(`removing ${amount} strings`);
        for (let i = 1; i < currentStrings + 1; i++) {
          if (i > this.strings) {
            delete place[i];
          }
        }
        this.stringSet = { ...place };
      }

      // check if the min and max lenght of stringRange is same; otherwise re-calculate
      if (this.multiRange.min != this.multiRange) {
        this.setMultiRange();
      }
    },
  },

  mounted() {
    this.selectedModel = this.models[0].id;
    // this.stringSet = this.models.find((m) => m.id == 1).preset;
    // this.stringSet = this.mode
  },
});
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
