var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',[_vm._v("#"+_vm._s(_vm.i))]),_c('td',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.string.note),expression:"string.note"}],staticClass:"form-select",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.string, "note", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l(([
          'A',
          'A#',
          'B',
          'C',
          'C#',
          'D',
          'D#',
          'E',
          'F',
          'F#',
          'G',
          'G#' ]),function(item){return _c('option',{key:item,domProps:{"value":item}},[_vm._v(" "+_vm._s(item)+" ")])}),0)]),_c('td',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.string.octave),expression:"string.octave"}],staticClass:"form-select",attrs:{"disabled":!_vm.string.note},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.string, "octave", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.uniqueNotes),function(item){return _c('option',{key:item,domProps:{"value":item}},[_vm._v(" "+_vm._s(item)+" ")])}),0)]),_c('td',{staticStyle:{"width":"20vw"}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.string.string_id),expression:"string.string_id"}],staticClass:"form-select",attrs:{"name":"","id":"lstparameters"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.string, "string_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){_vm.string.mass = null}]}},[_vm._v(" > "),_vm._l((_vm.producers),function(producer){return _c('optgroup',{key:producer,attrs:{"label":producer}},_vm._l((_vm.strings.filter(
            function (s) { return s.producer == producer && (s.active || !_vm.filteredStrings); }
          )),function(string){return _c('option',{key:string.id,domProps:{"value":string.id}},[_vm._v(" "+_vm._s(string.name)+" ")])}),0)})],2)]),_c('td',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.string.mass),expression:"string.mass"}],staticClass:"form-select",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.string, "mass", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.selectedMasses),function(item){return _c('option',{key:item.name,domProps:{"value":item.mass}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.string.length),expression:"string.length"}],staticClass:"form-control",attrs:{"type":"number"},domProps:{"value":(_vm.string.length)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.string, "length", $event.target.value)}}})]),_c('td',{class:!_vm.tension ? 'bg-warning' : ''},[_vm._v(" "+_vm._s(!_vm.tension ? "..." : _vm.tension)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }