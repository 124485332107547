export default [
  { name: "E0", frequency: 20.6 },
  { name: "F0", frequency: 21.9 },
  { name: "F#0", frequency: 23.2 },
  { name: "G0", frequency: 24.5 },
  { name: "G#0", frequency: 26 },
  { name: "A0", frequency: 27.5 },
  { name: "A#0", frequency: 29.2 },
  { name: "B0", frequency: 30.9 },
  { name: "C1", frequency: 32.7 },
  { name: "C#1", frequency: 34.7 },
  { name: "D1", frequency: 36.7 },
  { name: "D#1", frequency: 38.9 },
  { name: "E1", frequency: 41.2 },
  { name: "F1", frequency: 43.7 },
  { name: "F#1", frequency: 46.3 },
  { name: "G1", frequency: 49 },
  { name: "G#1", frequency: 51.9 },
  { name: "A1", frequency: 55 },
  { name: "A#1", frequency: 58.3 },
  { name: "B1", frequency: 61.7 },
  { name: "C2", frequency: 65.4 },
  { name: "C#2", frequency: 69.3 },
  { name: "D2", frequency: 73.4 },
  { name: "D#2", frequency: 77.8 },
  { name: "E2", frequency: 82.4 },
  { name: "F2", frequency: 87.3 },
  { name: "F#2", frequency: 92.5 },
  { name: "G2", frequency: 98 },
  { name: "G#2", frequency: 103.8 },
  { name: "A2", frequency: 110 },
  { name: "A#2", frequency: 116.5 },
  { name: "B2", frequency: 123.5 },
  { name: "C3", frequency: 130.8 },
  { name: "C#3", frequency: 138.6 },
  { name: "D3", frequency: 146.8 },
  { name: "D#3", frequency: 155.6 },
  { name: "E3", frequency: 164.8 },
  { name: "F3", frequency: 174.6 },
  { name: "F#3", frequency: 185 },
  { name: "G3", frequency: 196 },
  { name: "G#3", frequency: 207.7 },
  { name: "A3", frequency: 220 },
  { name: "A#3", frequency: 233.1 },
  { name: "B3", frequency: 246.9 },
  { name: "C4", frequency: 261.6 },
  { name: "C#4", frequency: 277.2 },
  { name: "D4", frequency: 293.7 },
  { name: "D#4", frequency: 311.1 },
  { name: "E4", frequency: 329.6 },
  { name: "F4", frequency: 349.2 },
  { name: "F#4", frequency: 370 },
  { name: "G4", frequency: 392 },
  { name: "G#4", frequency: 415.3 },
  { name: "A4", frequency: 440 },
];
