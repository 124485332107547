<template>
  <tr>
    <td>#{{ i }}</td>

    <td>
      <select v-model="string.note" class="form-select">
        <option
          :key="item"
          v-for="item in [
            'A',
            'A#',
            'B',
            'C',
            'C#',
            'D',
            'D#',
            'E',
            'F',
            'F#',
            'G',
            'G#',
          ]"
          :value="item"
        >
          {{ item }}
        </option>
      </select>
    </td>

    <td>
      <select
        v-model="string.octave"
        :disabled="!string.note"
        class="form-select"
      >
        <option :key="item" v-for="item in uniqueNotes" :value="item">
          {{ item }}
        </option>
      </select>
    </td>

    <td style="width: 20vw">
      <select
        name=""
        id="lstparameters"
        class="form-select"
        v-model="string.string_id"
        @change="string.mass = null"
      >
        >
        <optgroup
          :key="producer"
          v-for="producer in producers"
          :label="producer"
        >
          <option
            :key="string.id"
            v-for="string in strings.filter(
              (s) => s.producer == producer && (s.active || !filteredStrings)
            )"
            :value="string.id"
          >
            {{ string.name }}
          </option>
        </optgroup>
      </select>
    </td>
    <td>
      <select v-model="string.mass" class="form-select">
        <option
          :key="item.name"
          v-for="item in selectedMasses"
          :value="item.mass"
        >
          {{ item.name }}
        </option>
      </select>
    </td>
    <td>
      <input type="number" v-model="string.length" class="form-control" />
    </td>

    <td :class="!tension ? 'bg-warning' : ''">
      {{ !tension ? "..." : tension }}
    </td>
  </tr>
</template>

<script>
import pitch from "../data/pitch";
import strings from "../data/strings.json";

export default {
  props: ["i", "preset", "string", "rounded", "filteredStrings"],
  data() {
    return {
      stringType: "plain",
      note: null,
      octave: null,
      mass: null,
      pitch: null,
      length: 25.5,
      pitches: pitch,
      strings,
      selectedProducer: null,
    };
  },

  watch: {
    "string.string_id"(new_value) {
      // this.string.mass = null;
    },

    "string.length"(new_value) {
      this.string.length = Math.round(100 * new_value) / 100;
    },
  },

  computed: {
    selectedMasses() {
      if (this.string.string_id) {
        return this.strings.find((i) => i.id == this.string.string_id).strings;
      } else {
        return [];
      }
    },
    pitchy() {
      // return this.pitches[this.string.note + this.string.octave];
      if (this.string.note && this.string.octave) {
        return this.pitches.find(
          (i) => i.name == this.string.note + this.string.octave
        ).frequency;
      } else {
        return false;
      }
    },

    tension() {
      let r = this.string.mass; //Mass
      let c = this.pitchy; //Pitch
      let i = this.string.length; //scalelenght
      console.log(r, c, i);

      if (!(r || c || i)) {
        return undefined;
      }

      let l = (r * Math.pow(2 * c * i, 2)) / 386.4;
      let o;

      if (this.rounded) {
        o = Math.round(10 * l) / 10;
      } else {
        o = Math.round(1000 * l) / 1000;
      }

      return o;
    },

    producers() {
      let activeProducers = [...new Set(this.strings.map((s) => s.producer))];

      if (this.filteredStrings) {
        let reals = [];
        activeProducers.forEach((p) => {
          if (this.strings.find((i) => i.producer == p && i.active)) {
            reals.push(p);
          }
        });
        // return [...new Set(this.strings.map((s) => s.producer))];
        return reals;
      } else {
        return activeProducers;
      }
    },

    uniqueOctaves() {
      return [...new Set(this.pitches.map((p) => p.name[0]))];
    },

    uniqueNotes() {
      return [...new Set(this.pitches.map((p) => p.name[1]))];
    },

    stringTypes() {
      return Object.keys(this.strings);
    },

    selectedStringType() {
      if (this.selectedProducer && this.stringType) {
        return this.strings[this.selectedProducer][this.stringType];
      } else {
        return [];
      }
    },
  },

  methods: {
    setStrings(e) {
      let label = document.querySelector("#lstparameters option:checked")
        .parentElement.label;
      console.log(e.target);
      // e.data.producer;
      this.selectedProducer = label;
      // this.mass = this.selectedStringType[0].mass;
      this.mass = null;
    },
  },
};
</script>

<style>
table {
  max-width: 100vw;
}
tr {
  padding: 0px;
}
td {
  margin: 0px;
  padding: 0px;
  width: 10vw;
}
</style>
